@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&display=swap&subset=latin-ext');
$default_color: #434343;
$border_color: #EAEAEA;
$color_primary_text: #0028A0;
$color_primary_bg: #009FDF;
$color_table: #888D8F;
$background_primary: #174E59;
a {
  color: $color_primary_text;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
body {
  overflow: hidden;
  position: relative;
  #root {
    .root-drawer .logo svg {
      transform: translateX(-.8rem);
      &:nth-child(2) {
        transform: translateX(1.2rem);
      }
    }
    .root-drawer.opened .logo,
    .root-drawer.hover .logo {
      svg:nth-child(1) {
        transform: translateX(0);
      }
      svg:nth-child(2) {
        transform: translateX(2rem);
      }
    }
  }
}
body, html {
  height: calc(var(--vh, 1vh) * 100);
}
#root {
  background: $color_primary_text;
  flex-direction: column;
  height: 100vh;
  .root-content {
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1 auto;
    z-index: 1;
    .root-page-content {
      background: #fff;
      position: relative;
    }
  }
}
.MuiChip-root {
  background-color: #f4f9f3 !important;
}
//.data-grid
.data-grid {
  padding: 2.4rem 3rem 3rem;
  .data-grid-wrapper {
    border-color: $border_color;
    border-radius: 0;
    padding: 2rem;
  }
  .toolbar-head {
    padding: 0 0 2rem;
    .title {
      font-weight: bold;
    }
    .toolbar-controls li {
      button {
        height: 3.6rem;
        min-width: 10rem;
      }
    }
  }
  .toolbar-filters {
    background: #F3F8FA;
    border-radius: 0;
    margin: 0 0 1.5rem;
    padding: 2rem 2rem .5rem;
    svg {
      color: $color_primary_text;
    }
  }
  .data-grid-scroll {
    table {
      thead tr th {
        color: #8A8F91;
        font-weight: 500;
        line-height: 2.4rem;
        &:first-child {
          padding-left: .5rem;
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              padding-left: .5rem;
              &.is-edit-allow {
                padding-left: 1rem;
              }
            }
            &.swap {
              padding: 0;
            }
          }
        }
      }
    }
  }
  .data-grid-pagination {
    color: $color_table;
    .MuiFormControl-root {
      max-width: 8rem;
    }
    .size .total {
      span {
        font-weight: 600;
      }
    }
    .pagination {
      .disabled {
        display: none;
      }
      li {
        padding: 0 .3rem;
        a {
          color: #95A2A7;
          padding: .3rem .5rem;
          border-radius: 0;
          &:hover {
            color: $color_primary_text;
          }
        }
        &.active a {
          box-shadow: none;
          background: $color_primary_text;
          min-width: 3.6rem;
          color: #fff;
        }
      }
    }
  }
}
.grid-inner{
  padding: 0;
  height: 100%;
  .data-grid-wrapper{
    padding: 0;
    border: none;
  }
}
//form
.form-edit-wrapper {
  .form-edit-head {
    .title {
      font-weight: bold;
    }
    .form-edit-controls {
      padding: 0;
      white-space: nowrap;
    }
  }
}
.content-form-wrapper {
  display: flex;
  flex: 1 auto;
}
.form-edit-content {
  &.tabs {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    > .tab-content {
      padding: 2.5rem;
      display: flex;
      flex: 1 auto;
      &.hide {
        display: none;
      }
    }
  }
  .form-tabs .MuiTabs-root .MuiTab-root {
    padding: 2rem 2.5rem;
    flex-basis: auto;
    &.error {
      color: #f44336;
    }
  }
}
.form-edit-controls {
  padding: 2rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .MuiButton-root {
    height: 3.6rem;
    min-width: 10rem;
    &.btn-delete {
      background-color: transparent;
      box-shadow: none;
      font-size: 0;
      min-width: inherit;
      svg {
        font-size: 2rem;
        color: rgb(220, 0, 78);
      }
    }
  }
}
//content-object-form
.form-edit-wrapper.content-object-form {
  .form-edit-content {
    .tab-content {
      flex: 1 auto;
      overflow: hidden;
      &.content-object-forms-wrapper {
        padding: 0;
      }
      > div {
        overflow: hidden;
      }
    }
  }
}
.language-contents-wrap{
  display: flex;
  .languages-nav {
    width: 100%;
    min-width: 20rem;
    max-width: 20rem;
    margin: 2.4rem 0 2.4rem 1rem;
    border-right: 1px solid #EAEAEA;
    .MuiTab-labelIcon{
      min-height: unset;
    }
    .MuiTab-wrapper{
      align-items: flex-start;
      flex-direction: row;
      justify-content: flex-start;
    }
    .MuiTab-root {
      .MuiSvgIcon-root{
        color: grey;
      }
      &.active {
        .MuiSvgIcon-root{
          color: #5faf59;
        }
      }
      &.invalid {
        .MuiSvgIcon-root{
          color: #f44336;
        }
      }
    }
  }
  .forms-wrapper {
    flex: 1 auto;
    padding: 2.4rem 3.2rem;
    .lang-form {
      &.hide {
        display: none;
      }
    }
  }
}
.sync-notify {
  margin-top: -1.8rem;
  p {
    text-align: center;
    font-size: 1.6rem;
    padding: .5rem;
    margin: 0;
  }
}
//main nav
.main-nav {
  .title {
    .MuiListItemText-primary {
      color: $default_color;
    }
    .MuiListItemText-secondary {
      font-size: 1.1rem;
      opacity: .7;
      padding-top: .3rem;
    }
  }
  .active {
    > .icon-wrapper svg,
    > .title .MuiListItemText-primary {
      color: $color_primary_text;
    }
  }
  .MuiList-root .MuiList-root {
    padding-top: 1rem;
    padding-bottom: .5rem;
    .title {
      margin-top: 0;
      margin-bottom: 0;
      .MuiListItemText-primary {
        color: $default_color;
        font-size: 1.4rem;
      }
    }
  }
}
//!!!!TEMP
.data-grid-wrapper-style > .grid-footer > .pagination > li > a {
  &:hover,
  &.active {
    color: #0028A0;
  }
}
.main-nav > .level-3 .title .active {
  > .icon-wrapper svg,
  .title .MuiListItemText-primary {
    color: #0028A0;
  }
}
body[dir="rtl"] {
  .data-grid-pagination{
    .previous,
    .next{
      transform: rotate(180deg);
    }
  }
}
// fix colors
.image-uploader{
  .MuiSvgIcon-colorSecondary,
  .MuiButton-textSecondary{
    color: #0028A0;
  }
}
.dialog-alert{
  &__icon{
    color: rgb(220, 0, 78);
  }
}
.big-dialog{
  .MuiDialog-paper{
    height: 100%;
  }
  .MuiDialogContent-root{
    display: flex;
  }
}
.grid-inline-new{
  padding: 1rem 2rem;
  background-color: rgba(0, 159, 223, .1);
  margin-bottom: 1rem;
}
.MuiButton-root.btn-custom-delete {
  color: #dc004e;
  min-width: auto;
  svg {
    font-size: 2rem;
  }
}
